<template>

  <section id="our-team" class="c-team c-section">

    <div class="o-content-block o-content-block--1064">

      <SectionHeader title="Investment Team" />

      <p 
        dzanimate
        dzanimate-once
        dzanimate-direction="top"
        dzanimate-effect="fade-slide"
        dzanimate-delay=".5"
        class="c-section__headline"
      >
        We are investors passionate about technology and innovation, supported by a world-class team of science and technology advisors.
      </p>

      <div class="o-grid o-grid--col-3">

        <div 
          v-for="(person, index) in team" 
          :key="index"
          class="c-profile-card" 
          dzanimate
          dzanimate-once
          dzanimate-direction="top"
          dzanimate-effect="fade-slide"
          :dzanimate-delay="(index + 2) / 4"
        >

          <div class="c-profile-card__photo" href="#">

            <img class="c-profile-card__img u-grayscale" width="380" height="315" :src="person.image" :alt="$filters.stripHTML(person.name)">

            <a href="#" class="c-profile-card__btn o-btn o-btn--primary" @click.prevent="openModal(index)">view profile</a>

          </div>

          <h3 class="o-ttl o-ttl--20 o-ttl--hybrid" v-html="person.name"></h3>

        </div>

      </div>

    </div>

    <modal-component
      ref="teamModal"
      modClass="c-modal--team"
    >
  
      <carousel  
        ref="teamCarousel" 
        :settings="carouselSettings"
        :wrap-around="true"
        v-model="currentSlide"
      >

        <slide  
          v-for="(person, index) in team" 
          :key="index"
        >

          <div class="c-modal__wrapper">

            <div class="c-modal__media">

              <div class="c-modal__profile-card">
        
                <img class="c-modal__profile-img" width="380" height="315" :src="person.image" :alt="$filters.stripHTML(person.name)">
      
                <a :href="person.link" target="_blank" class="c-modal__profile-link">
      
                  <svg class="c-modal__profile-ico" width="21" height="20">
                    <title>Linkedin</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="#ico-linkedin"
                    />
                  </svg>
      
                </a>
      
              </div>

            </div>

            <div class="c-modal__content">

              <div class="c-modal__info">
  
                <div class="c-modal__header">
  
                  <h3 class="c-modal__ttl o-ttl o-ttl--20" v-html="person.name"></h3>
          
                  <h4 class="c-modal__subttl">{{ person.role }}</h4>
                  
                </div>
          
                <div class="c-modal__body">
  
                  <p v-html="person.bio"></p>
                  
                </div>
                  
              </div>
              
            </div>

            <!-- Modal Close -->
            <svg class="c-modal__close" width="21" height="20" @click="this.$refs.teamModal.closeModal()">
              <title>Close Modal</title>
              <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#ico-close"
              />
            </svg>
            <!-- End: Modal Close -->

          </div>

        </slide>

      </carousel>

      <carousel-controls 
        modClass="c-carousel-controls--default" 
        @prevSlide="$refs.teamCarousel.prev()" 
        @nextSlide="$refs.teamCarousel.next()" 
      />
          
    </modal-component>

  </section>

</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { Carousel, Slide } from 'vue3-carousel';
import CarouselControls from '@/components/CarouselControls.vue';

import photo1 from '@/assets/img/team/antonio-salles.jpg';
import photo2 from '@/assets/img/team/julio-benetti.jpg';
import photo4 from '@/assets/img/team/leonardo-sabo.jpg';
import photo5 from '@/assets/img/team/lorenzo-di-stefano.jpg';
import photo6 from '@/assets/img/team/luigi-quinto.jpg';

export default {
  name: 'TeamSection',
  components: {
    SectionHeader,
    ModalComponent,
    Carousel,
    Slide,
    CarouselControls
  },
  data() {
    return {
      showModal: false,
      currentSlide: 0,
      carouselSettings: {
        itemsToShow: 1
      },
      team: [
        {
          image: photo1,
          name: '<strong>Antonio</strong> Moreira Salles',
          role: 'Co-founder and General Partner',
          link: 'https://www.linkedin.com/in/amoreirasalles/',
          bio: `Antonio is an experienced venture capital investor with a successful track record in the United States, Europe, and Latin America.
          <br><br>
          Prior to co-founding MANDI, Antonio worked in the financial services industry for Morgan Stanley and Santander in London, as well as for General Atlantic in Shanghai and Beijing.
          <br><br>
          While in Brazil, Antonio served as Vice Chairman of the Board for Unear and led the launch of new products and services for the marketplace division of Casino Brazil.
          <br><br>
          Antonio also serves on the technology committee of a major Brazilian agricultural company and is actively involved in several socioeconomic initiatives for Amazon's  sustainable development.
          <br><br>
          Antonio has an MBA from Stanford Graduate School of Business and a Bachelor’s Degree in Business Administration from IBMEC-RJ in Brazil.`
        },
        {
          image: photo2,
          name: '<strong>Julio</strong> Benetti',
          role: 'Co-founder and General Partner',
          link: 'https://www.linkedin.com/in/julio-benetti/',
          bio: `Julio is a technology expert with experience in product development and business management in the US, Brazil, and Europe.
          <br><br>
          Before co-founding MANDI, Julio was one of the first employees at Samsara (NYSE: IOT) where he led the introduction of new software and hardware products. Julio also oversaw Samsara’s international expansion into Europe, Mexico, and Canada.
          <br><br>
          Prior to joining Samsara, Julio worked as a consultant for Boston Consulting Group in São Paulo and Paris.
          <br><br>
          Julio has an MBA from Stanford Graduate School of Business, a Masters in Science in Materials and Process Engineering from École Nationale d’Ingénieurs de Saint-Étienne, and a Bachelor’s Degree in Mechanical Engineering from UNICAMP in Brazil.`
        },
        {
          image: photo4,
          name: '<strong>Leonardo</strong> Sabó',
          role: 'Principal',
          link: 'https://www.linkedin.com/in/leonardosabo/',
          bio: `Leonardo is a finance and investment professional with extensive experience in corporate finance, mergers and acquisitions, and capital raising on a global scale.
<br><br>
Prior to joining MANDI, Leonardo worked for J.P. Morgan’s Latin America Investment Banking division in New York and São Paulo for over 6 years.
<br><br>
During his time at J.P. Morgan, Leonardo closed 28 transactions totaling over US$13.5 billion in total transaction value, including: JOKR/DAKI’s US$50 million Series C, Cosan’s US$4.5 billion acquisition of a minority stake in Vale, OLX Brazil’s R$2.9 billion acquisition of Grupo Zap, and Suzano’s US$1.2 billion follow-on offering.
<br><br>
Leonardo has a Bachelor’s Degree in Finance and Data Science from New York University’s Leonard N. Stern School of Business
`
        },
        {
          image: photo6,
          name: '<strong>Luigi</strong> Quinto',
          role: 'Associate',
          link: 'https://www.linkedin.com/in/luigi-quinto-858a631a6/',
          bio: `Luigi is a finance and investment professional with more than two years of experience in the Venture Capital and Venture Debt industries. 
 <br><br>
He has participated in over eight deals, successfully managing investments totaling over R$80 million in both equity and debt and was involved in the fundraising of a first time fund and a first time product early in his career.
 <br><br>
Prior to joining MANDI, Luigi was an Associate of the Investment Team at Bertha Capital and worked for Naia Capital’s Investment Team, accumulating strong expertise in company evaluation, negotiation, execution, and portfolio management within the dynamic field of venture financing.
 <br><br>
His previous transactions include investments in Rock Content, Clicksign, and Ziyou.
 <br><br>
Luigi has a Bachelor's Degree in Business Administration from Insper in São Paulo
`
        },
        {
          image: photo5,
          name: '<strong>Lorenzo</strong> Di Stefano',
          role: 'Analyst',
          link: 'https://www.linkedin.com/in/lorenzodi/',
          bio: `Lorenzo is a finance professional with over two years of expertise in alternative assets and venture capital. 
<br><br>
Managing investments for a firm with over US$500 million in AUM, spanning both liquid and illiquid assets, Lorenzo has proficiency in analyzing equity, debt, and various funds. 
<br><br>
His background includes valuable experience in the Investment Team at Mogno Capital, where he specialized in the evaluation of alternative funds and portfolio management, before joining MANDI.

`
        },
      ]
    }
  },
  methods: {
    openModal(i) {
      this.currentSlide = i;
      this.$refs.teamModal.openModal();
    }
  }
}
</script>